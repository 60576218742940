jQuery(document).ready(function ($) {
  var $slide = $(".section-slide .swiper-container");

  if (!$slide.length) return;

  var mySwiper = new Swiper($slide, {
    autoplay: {
      delay: 7000,
    },
    navigation: {
      prevEl: $slide.parent().find(".slide-navigation.prev"),
      nextEl: $slide.parent().find(".slide-navigation.next"),
    },
    speed: 550,
    pagination: {
      el: ".slide-pagination",
      clickable: true,
      bulletClass: "bullet",
      bulletActiveClass: "bullet-active",
      modifierClass: "slide-pagination-",
    },
  });
});
