jQuery(document).ready(function ($) {
    $('.box-search-city .box-list').mCustomScrollbar({
        scrollInertia: 300,
        scrollEasing: "linear"
    });

    var options = {
        valueNames: ['city']
    };

    var cityList = new List('city-search', options);
});