jQuery(document).ready(function ($) {
    var $testimony = $('.section-testimony .swiper-container');

    if (!$testimony.length) return

    var mySwiper = new Swiper($testimony, {
        autoplay: {
            delay: 7000,
        },
        slidesPerView: 3,
        speed: 550,
        spaceBetween: 30,
        pagination: {
            el: '.testimony-pagination',
            clickable: true,
            bulletClass: 'bullet',
            bulletActiveClass: 'bullet-active',
            modifierClass: 'slide-pagination-'
        },
        breakpoints: {
            991: {
                slidesPerView: 2
            },
            767: {
                slidesPerView: 1
            }
        }
    });
});