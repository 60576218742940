$(function () {
    $("#navbar-menu ul li a[href^='#']").on('click', function (e) {
        e.preventDefault()

        var hash = this.hash
        var $toggleMenu = $('.toggle-menu');

        $toggleMenu.slideUp(150);

        setTimeout(function () {
            $('html, body').animate({
                scrollTop: $(hash).offset().top
            }, 750)
        }, 200);
    })
});