$(function () {
    $(".anchor-link[href^='#']").on('click', function (e) {
        e.preventDefault()

        var hash = this.hash

        setTimeout(function () {
            $('html, body').animate({
                scrollTop: $(hash).offset().top
            }, 750)
        }, 200);
    })
});